import axios from "axios";
import { useQuery } from "react-query";
import { MessageWarning } from "./_models";


const API_URL = process.env.REACT_APP_API_URL;

const GET_MESSAGE = `${API_URL}/messages/main`;

export const useGetMessageWarning = (options: any = {}) => {
  return useQuery<MessageWarning>(
    "message-warning",
    async () => {
      const { data } = await axios.get(GET_MESSAGE);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};
