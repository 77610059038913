import clsx from "clsx";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { HeaderUserMenu } from "../../../partials";
import { useLayout } from "../../core";
import { useAuth } from "../../../../app/modules/auth";
import { useIntl } from "react-intl";
import { NavLink } from "../../../../app/pages/main-page/components/NavLink";
import { useLocation } from "react-router-dom";
import { HeaderWithdraw } from "./header-withdraw/HeaderWithdraw";

const itemClass = "ms-1 ms-lg-3";
// const btnClass =
// "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px";
const userAvatarClass = "symbol-35px symbol-md-40px";
const btnIconClass = "fs-1";

const Navbar = () => {
  const { config } = useLayout();
  const { currentUser } = useAuth();
  const intl = useIntl();

  const location = useLocation();
  const mainPath = location?.pathname?.split("/")[1];

  if (!currentUser) {
    return (
      <div className="ms-auto">
        <NavLink
          title={
            <div className="h-100 fw-bold fs-6 text-freewin d-flex align-items-center">
              {intl.formatMessage({ id: "MAIN.PAGE.LOGIN.TITLE" })}
            </div>
          }
          to="/auth"
        />
      </div>
    );
  }

  return (
    <div className="app-navbar w-100 w-lg-auto flex-shrink-0 d-flex gap-2">
      {/* {pathname.includes("/bet") ? (
        <>
          <div className="me-xl-0 d-flex flex-column align-items-end justify-content-center bg-gray-300 my-2 px-5 rounded">
            <span className="fs-8 text-gray-700">
              {intl.formatMessage({ id: "PROFILE_BK_EARNINGS" })}
            </span>
            <span className="fw-bold fs-3 text-white">
              {isFreewinDataLoading ? (
                <div
                  className="spinner-border spinner-border-sm"
                  role="status"
                ></div>
              ) : (
                <>{convertCentsToDollars(freewinData?.availableBalance)} </>
              )}
              <span className="text-success">$</span>
            </span>
          </div>
          <div className="me-auto me-xl-0 d-flex flex-column align-items-end justify-content-center bg-gray-300 my-2 px-5 rounded">
            <span className="fs-8 text-gray-700">
              {intl.formatMessage({ id: "PROFILE_BET_EARNINGS" })}
            </span>
            <span className="fw-bold fs-3 text-white">
              {isFreewinDataLoading ? (
                <div
                  className="spinner-border spinner-border-sm"
                  role="status"
                ></div>
              ) : (
                <>{convertCentsToDollars(freewinData?.outstanding)} </>
              )}
              <span className="text-success">$</span>
            </span>
          </div>
        </>
      ) : (
        <div className="me-auto me-xl-0 d-flex flex-column align-items-end justify-content-center bg-gray-300 my-2 px-5 rounded">
          <span className="fs-8 text-gray-700">
            {intl.formatMessage({ id: "PROFILE_EARNINGS" })}
          </span>
          <span className="fw-bold fs-3 text-white">
            {isWalletDataLoading ? (
              <div
                className="spinner-border spinner-border-sm"
                role="status"
              ></div>
            ) : (
              <>{convertCentsToDollars(walletData?.balance)} </>
            )}
            <span className="text-success">$</span>
          </span>
        </div>
      )} */}

      {/* <div className={clsx("app-navbar-item align-items-stretch", itemClass)}>
        <Search />
      </div>

      <div className={clsx("app-navbar-item", itemClass)}>
        <div id="kt_activities_toggle" className={btnClass}>
          <KTIcon iconName="chart-simple" className={btnIconClass} />
        </div>
      </div> */}

      {/* <div className={clsx("app-navbar-item", itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          className={btnClass}
        >
          <KTIcon iconName="element-plus" className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      {/* <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className={clsx("position-relative", btnClass)}
          id="kt_drawer_chat_toggle"
        >
          <KTIcon iconName="message-text-2" className={btnIconClass} />
          <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink" />
        </div>
      </div> */}

      {/* <div className={clsx("app-navbar-item", itemClass)}>
        <ThemeModeSwitcher
          toggleBtnClass={clsx("btn-active-light-primary btn-custom")}
        />
      </div> */}

      {mainPath === "bet" && <HeaderWithdraw />}

      <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <img src={toAbsoluteUrl("/media/avatars/blank.png")} alt="" />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div
          className="app-navbar-item d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="kt_app_header_menu_toggle"
          >
            <KTIcon iconName="text-align-left" className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  );
};

export { Navbar };
