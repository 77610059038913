import { FC } from "react";
import { useLocation } from "react-router";
import { Link, useParams } from "react-router-dom";
import clsx from "clsx";
import { checkIsActive } from "../../../../../_metronic/helpers";

type Props = {
  to: string;
  title: React.ReactNode;
  direction?: string;
};

export const NavLink: FC<Props> = ({ to, title, direction = undefined }) => {
  const { pathname } = useLocation();
  const { state } = useParams();

  return (
    <Link
      className={clsx("text-hover-freewin", {
        "text-white": !checkIsActive(pathname, to) || state !== direction,
        "text-freewin": checkIsActive(pathname, to) && state === direction,
      })}
      to={to}
    >
      {title}
    </Link>
  );
};
