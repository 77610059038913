import { isArray } from "lodash";

export const ShowError = (error: any) => {
  return (
    <div className="fv-plugins-message-container mt-4 ms-1 ">
      {isArray(error?.response?.data?.detail) ? (
        error?.response?.data?.detail?.map((error: any) => (
          <div
            data-field="appname"
            data-validator="notEmpty"
            className="fv-help-block"
          >
            {error.msg}
          </div>
        ))
      ) : error?.detail ? (
        <div
          data-field="appname"
          data-validator="notEmpty"
          className="fv-help-block"
        >
          {error?.detail}
        </div>
      ) : error?.response?.data?.detail ? (
        <div
          data-field="appname"
          data-validator="notEmpty"
          className="fv-help-block"
        >
          {error?.response?.data?.detail}
        </div>
      ) : (
        <>
          {typeof error === "string" && (
            <div
              data-field="appname"
              data-validator="notEmpty"
              className="fv-help-block"
            >
              {error}
            </div>
          )}
        </>
      )}
    </div>
  );
};
