export const convertCentsToDollars = (cents: number | undefined): number => {
  if (!cents) return 0;
  return cents / 100; // Деление на 100, чтобы перевести центы в доллары
};

export const convertDollarsToCents = (dollars: number | undefined): number => {
  if (!dollars) return 0;

  return Math.round(dollars * 100) // умножение на 100, чтобы перевести в центы
};
