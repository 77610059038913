import { Footer } from "./Footer";

const FooterWrapper = () => {
  return (
    <div className="app-footer" style={{ background: "#05162b" }}>
      <Footer />
    </div>
  );
};

export { FooterWrapper };
