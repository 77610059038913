/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { useIntl } from "react-intl";

import { UserContext } from "../../core/UserContextProvider";

import { DepositeEditModal } from "./modals/deposite-modal/DepositeEditModal";
import { WalletEditModal } from "./modals/wallet-modal/WalletEditModal";
import { WithdrawEditModal } from "./modals/withdraw-modal/WithdrawEditModal";
import { QueryWalletContext } from "../../core/QueryWalletResponseProvider";
import { useLocation } from "react-router-dom";
import { QRModal } from "./modals/qr-modal/QRModal";

interface Props {
  className: string;
  style: any;
}

const HOST = process.env.REACT_APP_PAYMENT_SITE_HOST;

export const Withdraw: React.FC<Props> = ({ className, style }) => {
  const intl = useIntl();
  const location = useLocation();
  const mainPath = location?.pathname?.split("/")[1];

  const { selectAccount, modalMode, setModalMode } = useContext(UserContext);

  const { tronWalletData } = useContext(QueryWalletContext);

  useEffect(() => {
    if (selectAccount?.action === "deposite") {
      setModalMode("DepositeEditModal");
    } else if (selectAccount?.action === "withdraw") {
      setModalMode("WithdrawEditModal");
    }
  }, [selectAccount]);

  return (
    <>
      <div className={`card ${className}`} style={style}>
        {/* begin::Header */}
        <div className="card-header border-0">
          <h3 className="card-title fw-bold text-dark">
            {intl.formatMessage({ id: "PROFILE_TRANSACTIONS" })}
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body pt-0">
          {mainPath === "bet" && (
            <div
              onClick={() => setModalMode("DepositeQRModal")}
              className="d-flex align-items-center bg-light-primary rounded p-5 mb-7 cursor-pointer"
            >
              {/* begin::Icon */}
              <span className="text-primary me-5">
                <KTIcon
                  iconName="scan-barcode"
                  className="text-primary fs-1 me-5"
                />
              </span>
              {/* end::Icon */}
              {/* begin::Title */}
              <div className="flex-grow-1 me-2">
                <span className="fw-bold text-gray-800 fs-6">
                  Пополнить баланс личного кабинета
                </span>
              </div>
            </div>
          )}

          <a
            href={`${HOST}/payment/pages/method/${tronWalletData?.address}`}
            target="_blank"
            className="d-flex align-items-center bg-light-danger rounded p-5 mb-7 cursor-pointer"
          >
            <span className=" text-danger me-5">
              <KTIcon
                iconName="credit-cart"
                className="text-danger fs-1 me-5"
              />
            </span>
            <div className="flex-grow-1 me-2">
              <span className="fw-bold text-gray-800 fs-6">
                Пополнить баланс с карты
              </span>
            </div>
          </a>
          {/* begin::Item */}
          <div
            onClick={() => setModalMode("DepositeEditModal")}
            className="d-flex align-items-center bg-light-success rounded p-5 mb-7 cursor-pointer"
          >
            {/* begin::Icon */}
            <span className="text-success me-5">
              <KTIcon
                iconName="arrow-right-left"
                className="text-success fs-1 me-5"
              />
            </span>
            {/* end::Icon */}
            {/* begin::Title */}
            <div className="flex-grow-1 me-2">
              <span className="fw-bold text-gray-800 fs-6">
                {intl.formatMessage({ id: "PROFILE.TOP.UP" })}
              </span>
            </div>
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div
            onClick={() => setModalMode("WithdrawEditModal")}
            className="d-flex align-items-center bg-light-warning rounded p-5 mb-7 cursor-pointer"
          >
            <span className=" text-warning me-5">
              <KTIcon iconName="bill" className="text-warning fs-1 me-5" />
            </span>

            <div className="flex-grow-1 me-2">
              <a className="fw-bold text-gray-800 fs-6">
                {intl.formatMessage({ id: "PROFILE.TOP.DOWN" })}
              </a>
            </div>
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div
            onClick={() => {
              setModalMode("WalletEditModal");
            }}
            className="d-flex align-items-center bg-light-info rounded p-5 mb-3 cursor-pointer"
          >
            {/* begin::Icon */}
            <span className=" text-info me-5">
              <KTIcon iconName="wallet" className="text-info fs-1 me-5" />
            </span>
            {/* end::Icon */}
            {/* begin::Title */}
            <div className="flex-grow-1 me-2">
              <a className="fw-bold text-gray-800 fs-6">
                {intl.formatMessage({ id: "PROFILE.TOP.DOWN.WALLET" })}
              </a>
            </div>
            {/* end::Title */}
          </div>
          {/* end::Item */}
        </div>
        {/* end::Body */}

        {modalMode === "DepositeQRModal" && <QRModal />}
        {modalMode === "DepositeEditModal" && <DepositeEditModal />}
        {modalMode === "WithdrawEditModal" && <WithdrawEditModal />}
        {modalMode === "WalletEditModal" && <WalletEditModal />}
      </div>
    </>
  );
};
