import { useContext, useEffect } from "react";
import { KTIcon } from "../../../../../../../_metronic/helpers";
import { WithdrawEditModalForm } from "./WithdrawEditModalForm";
import { UserContext } from "../../../../core/UserContextProvider";
import { QueryWalletContext } from "../../../../core/QueryWalletResponseProvider";

const WithdrawEditModal = () => {
  const { setError } = useContext(QueryWalletContext);
  const { setModalMode } = useContext(UserContext);

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-650px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <div className="modal-header px-lg-10 py-lg-10  pt-lg-15 pb-lg-0 border-0 ">
              <div className="modal-header__title w-100 text-center">
                <h2 className="mt-3">Вывод с игрового баланса</h2>
              </div>
              {/* begin::Close */}
              <div
                className="ms-5 modal-close btn btn-sm btn-icon btn-active-color-primary"
                onClick={() => {
                  setError(null);
                  setModalMode(undefined);
                }}
              >
                <KTIcon className="fs-1" iconName="cross" />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mb-5">
              <WithdrawEditModalForm />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className="modal-backdrop fade show"></div>
      {/* end::Modal Backdrop */}
    </>
  );
};

export { WithdrawEditModal };
