import type { FC } from "react";
import React, { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useSearchParams, useNavigate } from "react-router-dom";

interface Props {
  method: (token: any) => void;
}

export const ConfirmOperation: FC<Props> = React.memo(({ method }) => {
  const [loading, setLoading] = useState(false);
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = params.get("token");
    const confirm = async () => {
      try {
        setLoading(true);

        const result = await method(token as any);

        setLoading(false);

        navigate("/crafted/pages/profile", {
          state: {
            action: {
              type: "SUCCESS_OPERATION_CONFIRM",
              title: "Статус транзакции",
              text: "Операция подтверждена",
            },
          },
        });
      } catch (error: any) {
        setLoading(false);

        // const textError = error?.response?.data?.detail
        //   ? `Ошибка подтверждения операции: ${error?.response?.data?.detail}`
        //   : "Ошибка подтверждения операции";

        navigate("/crafted/pages/profile", {
          state: {
            action: {
              type: "ERROR_OPERATION_CONFIRM",
              title: "Статус транзакции",
              text: "Ошибка подтверждения операции.",
            },
          },
        });
      }
    };

    if (token) {
      confirm();
    } else {
      navigate("/");
    }
  }, [method, navigate, params]);

  return (
    <Card className="p-5 w-50 m-auto">
      <div className="text-center mb-10">
        <h1>Подтверждение операции</h1>

        {loading && <Spinner animation="border" variant="primary" />}
      </div>
    </Card>
  );
});
