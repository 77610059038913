/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { getUserByToken, login, useConfirmLoginTotp } from "../core/_requests";
import { useAuth } from "../core/Auth";
import { useIntl } from "react-intl";
import { LoginHeader } from "./LoginHeader";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { ModalLayout } from "../../../../_metronic/partials/modals/ModalLayout";
import { removeAllSpaces, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { TotpLoginModel } from "../core/_models";
import { ShowError } from "../../errors/ShowError";

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

type ResponseTotp = {
  sub: string;
};

const initialValuesMask = ["_", "_", "_", "_", "_", "_"];
const regex = /^[0-9]+$/;

export function Login() {
  // const totpInputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState("");
  const [inputMaskValues, setInputMaskValues] = useState(initialValuesMask);

  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState<any>();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const [modalData, setModalData] = useState<any | null>(null);
  const [error, setError] = useState<any | null>(null);

  const { saveAuth, setCurrentUser } = useAuth();
  const intl = useIntl();

  const {
    mutate: confirmLoginTotp,
    isLoading: isConfirmLoginTotpLoading,
    error: confirmLoginTotpError,
    data: confirmLoginTotpData,
  } = useConfirmLoginTotp();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (!regex.test(value) && value !== "") return;

    if (value.length > 6) {
      setError("Код состоит из шести символов");
    } else if (value.length <= 6) {
      if (error) {
        setError(null);
      }

      const splitValue: string[] = value.split("");
      const newValues: string[] = [];

      setInputValue(value);

      for (let i = 0; i < 6; i++) {
        if (splitValue[i]) {
          newValues.push(splitValue[i]);
        } else {
          newValues.push("_");
        }
      }

      setInputMaskValues(newValues);
    }
  };

  const handleSubmitTotp = () => {
    if (inputValue.length < 6) {
      setError("Код состоит из шести символов");
      return;
    }

    const totpData: TotpLoginModel = {
      verify_code: inputValue,
      sub: modalData?.responseData.sub,
    };

    confirmLoginTotp(totpData);
  };

  const loginSchema = Yup.object().shape({
    username: Yup.string().required(
      intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })
    ),

    password: Yup.string()
      .min(
        3,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 3 })
      )
      .max(
        50,
        intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }, { length: 50 })
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
  });

  const initialValues = {
    username: ``,
    password: ``,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const params = new URLSearchParams();
        params.append("username", values.username);
        params.append("password", values.password);

        const auth = await login(params, token);

        if (auth?.sub) {
          TotpConfirm(auth as ResponseTotp);
          return;
        }

        saveAuthUser(auth);
      } catch (error: any) {
        console.error(error);
        saveAuth(undefined);
        const statusText =
          (error?.response?.data?.detail?.[0]?.msg ||
            error?.response?.data?.detail) ??
          intl.formatMessage({ id: "AUTH.VALIDATION.WRONG_CREDITINALS" });
        setStatus(statusText);
        setSubmitting(false);
        setLoading(false);
        setRefreshReCaptcha((r) => !r);
      }
    },
  });

  const saveAuthUser = async (auth: any) => {
    saveAuth(undefined);
    saveAuth(auth);
    const user = await getUserByToken();
    setCurrentUser(user);
    setRefreshReCaptcha((r) => !r);
  };

  const closeModal = () => {
    setInputValue("");
    setInputMaskValues(initialValuesMask);

    setError(null);
    setModalData(null);
    setLoading(false);
  };

  useEffect(() => {
    return closeModal();
  }, []);

  useEffect(() => {
    if (confirmLoginTotpData?.access_token) saveAuthUser(confirmLoginTotpData);
  }, [confirmLoginTotpData]);

  useEffect(() => {
    if (confirmLoginTotpError) setError(confirmLoginTotpError);
  }, [confirmLoginTotpError]);

  useEffect(() => {
    if (formik?.values?.username) {
      formik.values.username = removeAllSpaces(formik?.values?.username);
    }
  }, [formik?.values?.username]);

  useEffect(() => {
    if (formik?.values?.password) {
      formik.values.password = removeAllSpaces(formik?.values?.password);
    }
  }, [formik?.values?.password]);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={
        process.env.REACT_APP_GOOGLE_PUBLIC_KEY ??
        "6Lfmh30nAAAAAHZaOqxgTAEYSAPBHVHNYhDC2DBm"
      }
    >
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        <LoginHeader />
        {formik.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        <div className="fv-row mb-8">
          {/* <label className="form-label fs-6 fw-bolder text-dark">
            {intl.formatMessage({ id: 'AUTH.INPUT.USERNAME.TITLE' })}
          </label> */}
          <input
            placeholder={intl.formatMessage({
              id: "AUTH.INPUT.USERNAME.TITLE",
            })}
            {...formik.getFieldProps("username")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.username && formik.errors.username,
              },
              {
                "is-valid": formik.touched.username && !formik.errors.username,
              }
            )}
            type="text"
            name="username"
            autoComplete="off"
          />
          {formik.touched.username && formik.errors.username && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.username}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        {/* begin::Form group */}
        <div className="fv-row mb-3">
          {/* <label className="form-label fw-bolder text-dark fs-6 mb-0">
            {intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD.TITLE' })}
          </label> */}
          <input
            placeholder={intl.formatMessage({
              id: "AUTH.INPUT.PASSWORD.TITLE",
            })}
            type="password"
            autoComplete="off"
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              {
                "is-valid": formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        {/* begin::Wrapper */}
        <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
          <div />

          {/* begin::Link */}
          <Link to="/auth/forgot-password" className="link-primary">
            {intl.formatMessage({ id: "AUTH.GENERAL.FORGOT_BUTTON" })} ?
          </Link>
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}
        {/* begin::Action */}

        <GoogleReCaptcha
          onVerify={setToken}
          refreshReCaptcha={refreshReCaptcha}
        />

        <div className="d-grid mb-6 mb-lg-8">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-primary"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className="indicator-label">
                {intl.formatMessage({ id: "AUTH.GENERAL.SIGNUP_BUTTON" })}
              </span>
            )}
            {/* {!token && (
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            )} */}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "GLOBAL.WAIT",
                })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          {/* <Link to="/">
            <button
              type="button"
              id="kt_login_signup_form_cancel_button"
              className="btn btn-lg btn-light-primary w-100 mt-5"
            >
              {intl.formatMessage({
                id: "AUTH.GENERAL.BACK_BUTTON",
              })}
            </button>
          </Link> */}
        </div>
        {/* end::Action */}
        <div className="text-gray-500 text-center fw-semibold fs-6">
          {intl.formatMessage({ id: "AUTH.GENERAL.NO_ACCOUNT" })}{" "}
          <Link reloadDocument to="/auth/registration" className="link-primary">
            {intl.formatMessage({ id: "AUTH.GENERAL.REGISTER_BUTTON" })}
          </Link>
        </div>
      </form>

      <ModalLayout
        title={modalData?.title}
        show={Boolean(modalData)}
        handleClose={() => closeModal()}
      >
        <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10">
          <div className="form w-100" id="kt_sing_in_two_factor_form  ">
            <div className="text-center mb-10">
              <img
                alt="Logo"
                className="mh-125px w-100 h-100"
                src={toAbsoluteUrl("/media/svg/misc/smartphone-2.svg")}
              />
            </div>

            <div className="text-center mb-12">
              <h1 className="text-gray-900 mb-3">Two-Factor Verification</h1>

              {/* <div className="text-muted fw-semibold fs-5 mb-5">
                Введите одноразовый код из Google аутентификатора
              </div> */}

              {/* <div className="fw-bold text-gray-900 fs-3">******7859</div> */}
            </div>

            <div className="mb-4 w-100 ">
              <div className="fw-bold text-center text-gray-900 fs-6 mb-8 ms-1">
                Введите одноразовый код из Google аутентификатора
              </div>

              <div
                className="d-flex flex-wrap flex-stack justify-content-center aligin-items-center "
                style={{
                  position: "relative",
                }}
              >
                {inputMaskValues.map((value, index) => (
                  <div
                    key={index}
                    // onClick={() => handleDivClick(index)}
                    className="display-3"
                    style={{ cursor: "pointer", marginRight: "10px" }}
                  >
                    {value}
                  </div>
                ))}

                <input
                  type="phone"
                  // ref={totpInputRef}
                  value={inputValue}
                  style={{
                    opacity: "0",
                    position: "absolute",
                    width: "60%",
                    height: "100%",
                    top: "0",
                    left: "20%",
                  }}
                  className="form-control form-control-lg form-control-solid"
                  name="totpInputRef"
                  placeholder="XXX-XXX"
                  autoComplete="off"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-4">{error && ShowError(error)}</div>
        {/*end::Form Group */}
        <div className="d-flex aligin-items-center justify-content-center mx-12 mb-6 mt-12">
          <button
            type="button"
            className="d-block me-auto btn btn-primary  px-12 "
            onClick={closeModal}
          >
            Отмена
          </button>
          <button
            type="button"
            disabled={modalData?.isLoading}
            className="d-block ms-auto btn btn-primary px-6 "
            onClick={handleSubmitTotp}
          >
            {modalData?.isLoading && (
              <span
                className="spinner-border spinner-border-sm me-4"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {modalData?.btnText}
          </button>
        </div>
      </ModalLayout>
    </GoogleReCaptchaProvider>
  );

  function TotpConfirm(sub: ResponseTotp) {
    setModalData({
      title: "Введите ключ 2fa",
      responseData: sub,
      btnText: "Подтвердить",
      isLoading: isConfirmLoginTotpLoading,
    });
  }
}
