import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { Card, Button, Alert, Spinner } from "react-bootstrap";
import { confirmRegistration } from "../core/_requests";

export const ConfirmRegistration = () => {
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = params.get("token");

    const confirm = async () => {
      try {
        setLoading(true);
        await confirmRegistration(token as any);
        navigate("/dashboard");
      } catch (err) {
        setError("Ошибка подтверждения регистрации");
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      confirm();
    } else {
      navigate("/auth");
    }
  }, [navigate, params]);

  return (
    <Card className="p-5">
      <div className="text-center mb-10">
        <h1>Подтверждение регистрации</h1>

        {loading && <Spinner animation="border" variant="primary" />}
      </div>

      {error && <Alert variant="danger">{error}</Alert>}

      <div className="d-grid">
        <Button variant="primary" size="lg" onClick={() => navigate("/auth")}>
          Вернуться на страницу входа
        </Button>
      </div>
    </Card>
  );
};
