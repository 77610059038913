import type { FC } from "react";
import React from "react";

interface Props {}

export const FreeBetLogo: FC<Props> = React.memo(() => {
  return (
    <span
      className="text-freewin text-uppercase fw-bold fst-italic fs-2"
      style={{ fontFamily: "roboto" }}
    >
      freewin
    </span>
  );
});
