/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { Logout, AuthPage, useAuth } from "../modules/auth";
import { App } from "../App";
import { MainPage } from "../pages/main-page";
import { Faq } from "../pages/faq";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { currentUser } = useAuth();

  const HOST = process.env.REACT_APP_PINNACLE_ORIGIN;

  // Allow window to listen for a postMessage
  const pinnacleOrigin: any = HOST;
  window.addEventListener("message", (event) => {
    // check pinnacle origin

    if (event.origin && event.origin.toLowerCase().endsWith(pinnacleOrigin)) {
      const postData = event.data;
      switch (postData.action) {
        case "OPEN_WINDOW":
          const url = postData.url;
          window.open(url);
          break;
        default:
          break;
      }
    }
  });
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          <Route element={<MasterLayout />}>
            <Route path="faq/" element={<Faq />} />
          </Route>
          {currentUser ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              {/* <Route index element={<Navigate to="/" />} /> */}
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
              <Route element={<MasterLayout />}>
                <Route path="/" element={<MainPage />} />
              </Route>
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
