import { FC, useEffect, useRef, useState } from "react";
import React from "react";
import { useIntl } from "react-intl";
import QRCode from "react-qr-code";

import { KTSVG } from "../../../../_metronic/helpers";
import { Tooltip } from "bootstrap";
import { TronAddress } from "../../../../api/core-contract";
import { useLocation } from "react-router-dom";

interface Props {
  className: string;
  tronWalletData: TronAddress | undefined;
  isFetching: boolean;
}

export const TopUPQRCode: FC<Props> = React.memo(
  ({ isFetching, className, tronWalletData }) => {
    const intl = useIntl();

    const location = useLocation();
    const mainPath = location?.pathname?.split("/")[1];

    const [tooltip, setTooltip] = useState<any>();
    const ref = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
      if (tronWalletData && ref.current) {
        const tooltip = new Tooltip(ref.current, {
          trigger: "manual",
          title: "Скопированно",
          offset: [0, 5],
        });
        setTooltip(tooltip);
      }
    }, [tronWalletData]);

    const copyToClipBoard = async (address: string | undefined) => {
      try {
        await navigator.clipboard.writeText(address ?? "");
        if (tooltip) {
          tooltip.show();
          setTimeout(() => {
            tooltip.hide();
          }, 800);
        }
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    };

    return (
      <div className={`card ${className}`}>
        {/* begin::Header */}
        {mainPath !== "bet" && (
          <div className="card-header border-0">
            <h3 className="card-title align-items-start flex-column text-center w-100">
              <span className="card-label fw-bold text-dark m-auto">
                {intl.formatMessage({ id: "PROFILE.TOP.UP.LK" })}
              </span>
            </h3>
          </div>
        )}
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-0">
          {isFetching ? (
            <div
              className="spinner-border spinner-border-sm"
              role="status"
            ></div>
          ) : (
            <>
              <div
                className="p-5 bg-white rounded m-auto mb-4"
                style={{ width: "75%", maxWidth: "402px" }}
              >
                {tronWalletData?.address && (
                  <QRCode
                    size={256}
                    style={{
                      display: "block",
                      height: "auto",
                      width: "100%",
                      margin: "auto",
                    }}
                    value={tronWalletData.address}
                    viewBox={`0 0 256 256`}
                  />
                )}
              </div>
              <div className="fs-6 pt-3 d-flex justify-content-center align-items-center">
                {tronWalletData?.address}
                <button
                  ref={ref}
                  className="ms-5 p-0 btn btn-light btn-active-white"
                  style={{ background: "none" }}
                  onClick={() => copyToClipBoard(tronWalletData?.address)}
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen054.svg"
                    className="svg-icon-muted svg-icon-2hx m-0"
                  />
                </button>
              </div>
            </>
          )}
        </div>
        {/* end::Body */}
      </div>
    );
  }
);
