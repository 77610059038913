import type { FC } from "react";
import React from "react";
import { ModalLayout } from "./ModalLayout";

interface Props {
  title: string;
  text: string;
  additional?: React.ReactNode;
  setModalData: (isSucessRegistrationModal: boolean) => void;
}

export const DialogModal: FC<Props> = React.memo(
  ({ setModalData, title, text, additional }) => {
    return (
      <ModalLayout
        title={title}
        show={true}
        handleClose={() => setModalData(false)}
        onOk={() => setModalData(false)}
      >
        <div>
          {/*begin::Form Group */}
          <div className="fv-row">
            <span className="fs-4">{text}</span>
          </div>
          {additional && additional}
        </div>
      </ModalLayout>
    );
  }
);
