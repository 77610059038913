import type { FC } from "react";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "./components/NavLink";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
// import { TickerLine } from "./components/TickerLine";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { useAuth } from "../../modules/auth";
import { useLocation } from "react-router-dom";
import { DialogModal } from "../../../_metronic/partials/modals/DialogModal";
// import { DefaultConfig, useLayout } from "../../../_metronic/layout/core";

interface Props {}

export const MainPage: FC<Props> = React.memo(() => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const location = useLocation();
  // const { setLayout } = useLayout();

  const [isSucessRegistrationModal, showSucessRegistrationModal] =
    useState(false);

  useEffect(() => {
    if ((location.state as any)?.action?.type === "SUCCESS_REGISTERED") {
      showSucessRegistrationModal(true);
    }
  }, [location.state]);

  // useEffect(() => {
  //   setLayout({
  //     ...DefaultConfig,
  //     ...{
  //       app: {
  //         ...DefaultConfig.app,
  //         content: {
  //           ...DefaultConfig.app?.content,
  //         },
  //       },
  //     },
  //   });

  //   return () => {
  //     setLayout(DefaultConfig);
  //   };
  // }, []);

  return (
    <div className="d-flex flex-column justify-content-between text-white">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <img
              src={toAbsoluteUrl("/media/bg/main-pic-1.png")}
              alt="main-logo"
              style={{ width: "85%", display: "block", margin: "0 auto 0" }}
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row align-items-center">
          <div className={clsx("col-lg-8", "fw-bolder", styles.freeWinHeader)}>
            {intl.formatMessage({ id: "MAIN.PAGE.HEADER.TITLE" })}
          </div>
          <div className="col text-lg-end text-center">
            <NavLink
              title={
                <button
                  type="button"
                  className={clsx("btn", styles.freeWinBtn)}
                  // style={{ height: "78px", width: "325px", fontSize: "24px" }}
                >
                  <SVG
                    src={toAbsoluteUrl("/media/icons/cup.svg")}
                    width={27}
                    height="auto"
                    title="Telegram"
                    className="me-4"
                  />
                  {intl.formatMessage({ id: "MAIN_PAGE_GO_TO_BET" })}
                </button>
              }
              to={currentUser ? "/bet/rates" : "/auth"}
            />
          </div>
        </div>
      </div>

      {/* <div className="container-fluid">
        <div className="row" style={{ margin: "146px 0 226px 0" }}>
          <TickerLine />
        </div>
      </div> */}

      {/* <div className="container">
        <div
          className="row justify-content-evenly"
          style={{ marginBottom: "80px", fontWeight: "500", fontSize: "22px" }}
        >
          <div
            className="col d-flex flex-column align-items-center justify-content-end position-relative"
            style={{
              background: "#05162b",
              borderRadius: "10px",
              flex: "0 1 520px",
              height: "335px",
            }}
          >
            <img
              className="position-lg-absolute"
              src={toAbsoluteUrl("/media/bg/benefit-1-image.png")}
              alt=""
              style={{ width: "40vmin", top: "-85px" }}
            />
            <div className="p-5 text-start w-100">
              Различные уровни участия и повышенный кэшбэк для продвинутых
              пользователей
            </div>
          </div>
          <div
            className="col d-flex flex-column align-items-center justify-content-end position-relative"
            style={{
              background: "#05162b",
              borderRadius: "10px",
              flex: "0 1 520px",
              height: "335px",
            }}
          >
            <img
              className="position-absolute"
              src={toAbsoluteUrl("/media/bg/benefit-2-image.png")}
              alt=""
              style={{ width: "35vmin", top: "-80px" }}
            />
            <div className="p-5 text-start w-100">
              Наглядная аналитика ваших ставок и платежей в личном кабинете
            </div>
          </div>
        </div>

        <div
          className="row justify-content-evenly"
          style={{
            paddingBottom: "150px",
            fontWeight: "500",
            fontSize: "22px",
          }}
        >
          <div
            className="col d-flex flex-column align-items-center justify-content-start position-relative"
            style={{
              background: "#05162b",
              borderRadius: "10px",
              flex: "0 1 520px",
              height: "335px",
            }}
          >
            <div className="p-5 text-start w-100">
              Пополнение и вывод средств на криптокошелек
            </div>
            <img
              className="position-absolute"
              src={toAbsoluteUrl("/media/bg/benefit-3-image.png")}
              alt=""
              style={{ width: "34vmin", top: "80px" }}
            />
          </div>
          <div
            className="col d-flex flex-column align-items-center justify-content-start position-relative"
            style={{
              background: "#05162b",
              borderRadius: "10px",
              flex: "0 1 520px",
              height: "335px",
            }}
          >
            <div className="p-5 text-start w-100">
              Регулярное начисление бонусов и постоянно обновляющиеся акции
            </div>
            <img
              className="position-absolute"
              src={toAbsoluteUrl("/media/bg/benefit-4-image.png")}
              alt=""
              style={{ width: "37vmin", top: "85px" }}
            />
          </div>
        </div>
      </div> */}
      {isSucessRegistrationModal && (
        <DialogModal
          title={intl.formatMessage({ id: "AUTH.GENERAL.REGISTERED_SUCCESS" })}
          text={intl.formatMessage({
            id: "AUTH.GENERAL.REGISTERED_SUCCESS_MESSAGE",
          })}
          setModalData={showSucessRegistrationModal}
        />
      )}
    </div>
  );
});
