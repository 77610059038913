import { FC, useContext, useEffect, useState } from "react";
import React from "react";
import { useFreeWinLogin } from "../../modules/profile/core/_requests";
import { DefaultConfig, useLayout } from "../../../_metronic/layout/core";
import { useParams } from "react-router-dom";
import { UserContext } from "../../modules/profile/core/UserContextProvider";
import { useIntl } from "react-intl";

interface Props {}

export const BetPage: FC<Props> = React.memo(() => {
  const { setLayout } = useLayout();

  const intl = useIntl();

  const { locale, view, isDataLoaded } = useContext(UserContext);
  const { state } = useParams();

  const [isIframeLoading, setIsIframeLoading] = useState(true);

  useEffect(() => {
    setLayout({
      ...DefaultConfig,
      ...{
        app: {
          ...DefaultConfig.app,
          content: {
            ...DefaultConfig.app?.content,
          },
          footer: { ...DefaultConfig.app?.footer, display: false },
        },
      },
    });

    return () => {
      setLayout(DefaultConfig);
    };
  }, []);

  const {
    mutate: getFreeWinLogin,
    isLoading: isFreeWinLoginLoading,
    data,
  } = useFreeWinLogin();

  // useEffect(() => {
  //   getFreeWinLogin({locale, view});
  // }, [getFreeWinLogin]);

  useEffect(() => {
    if (isDataLoaded) {
      if (state === intl.formatMessage({ id: "BET.LINK" })) {
        getFreeWinLogin({ locale, view });
      } else if (state === intl.formatMessage({ id: "ESPORTS.LINK" })) {
        getFreeWinLogin({ locale, view: "ESPORTS-HUB" });
      }
    }
  }, [isDataLoaded, state]);

  return (
    <>
      {data?.loginUrl && (
        <iframe
          title="Bet"
          className="position-absolute"
          style={{ top: "0", left: "0", width: "100%", height: "100%" }}
          width="100%"
          src={data?.loginUrl}
          onLoad={() => setIsIframeLoading(false)}
        />
      )}
      {(isFreeWinLoginLoading || isIframeLoading || !isDataLoaded) && (
        <div
          className="spinner-border"
          role="status"
          style={{ margin: "20% 0 0 48%" }}
        />
      )}
    </>
  );
});
