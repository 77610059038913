import axios from "axios";
import { useQuery } from "react-query";
import { FaqCategoriesSchemas } from "../../../api/core-contract";

const API_URL = process.env.REACT_APP_API_URL;
const FAQ = `${API_URL}/faq/`;

export const useGetFaq = (options: any = {}) => {
  return useQuery<FaqCategoriesSchemas[]>(
    "faq",
    async () => {
      const { data } = await axios.get(FAQ);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};
