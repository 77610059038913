import { useEffect, useState } from "react";
import { KTIcon } from "../../../helpers";
import { useGetMessageWarning } from "./core/_requests";

interface LSData {
  isActive: boolean;
  id?: number;
}

export function MessageBot() {
  const [isShowAlert, setIsShowAlert] = useState(false);

  const { data: messageData } = useGetMessageWarning();

  useEffect(() => {
    if (messageData?.active) {
      const msgLS = localStorage.getItem("message-warning");

      if (msgLS) {
        const parsedData: LSData = JSON.parse(msgLS);

        if (parsedData.id !== messageData.counter) {
          updateLocalStorage({ isActive: true, id: messageData?.counter });
        }
      } else {
        updateLocalStorage({ isActive: true, id: messageData?.counter });
      }
    } else if (messageData && !messageData?.active) {
      updateLocalStorage({ isActive: false, id: messageData?.counter });
    }
  }, [messageData]);

  const updateLocalStorage = (data: LSData) => {
    localStorage.setItem("message-warning", JSON.stringify(data));
    setIsShowAlert(data.isActive);
  };

  const acceptMessage = () => {
    updateLocalStorage({ isActive: false, id: messageData?.counter });
  };

  return (
    <>
      {isShowAlert && messageData && (
        <div
          className="notice d-flex  rounded border-primary border border-dashed p-6 my-4"
          style={{
            position: "fixed",
            zIndex: "105",
            bottom: "1%",
            left: "50%",
            transform: "translateX(-50%)",
            width: "90%",
            backgroundColor: "rgba(33, 46, 72, 1)",
          }}
        >
          <KTIcon iconName="information" className="fs-5x text-primary me-5" />
          <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
            <div className="mb-3 mb-md-0 fw-bold">
              <h4 className="text-gray-800 fw-bolder">Технические работы</h4>
              <div className="fs-6 text-gray-600 pe-7">
                {messageData.message}
              </div>
            </div>

            <button
              className="btn btn-primary px-6 align-self-center text-nowrap"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_two_factor_authentication"
              onClick={acceptMessage}
            >
              Принять
            </button>
          </div>
        </div>
      )}
    </>
  );
}
