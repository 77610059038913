import { FC, useContext, useRef } from "react";

import {
  convertCentsToDollars,
  convertDollarsToCents,
} from "../../../../../../utils";

import { QueryWalletContext } from "../../../../core/QueryWalletResponseProvider";
import { useIntl } from "react-intl";
import { UserContext } from "../../../../core/UserContextProvider";
import { ShowError } from "../../../../../errors/ShowError";
import { useQueryClient } from "react-query";

const DepositeEditModalForm: FC = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const amountBkTnputRef = useRef<HTMLInputElement>(null);

  const { walletData, isAllLoading, setDepositFreewin, error, setError } =
    useContext(QueryWalletContext);
  const { setModalMode } = useContext(UserContext);

  const accessibleWalletData = convertCentsToDollars(
    (walletData?.balance as number) - 100 <= 0
      ? 0
      : (walletData?.balance as number) - 100
  );

  const maxWalletValue = () => {
    if (amountBkTnputRef.current) {
      amountBkTnputRef.current.value = accessibleWalletData.toString();
    }
  };

  const createSendDataFreewin = (cents: number) => cents;

  const cancel = () => {
    setError(null);
    setModalMode(undefined);
  };

  return (
    <>
      <div>
        <div className="fv-row mb-7 p-3">
          <label className="d-flex align-items-center fs-5 fw-semibold mb-3 ms-2">
            <span className="">
              {intl.formatMessage({ id: "GLOBAL.ENTER_AMOUNT" })}
            </span>
          </label>
          <div className="mb-2" style={{ position: "relative", width: "100%" }}>
            <input
              type="number"
              ref={amountBkTnputRef}
              className="form-control form-control-lg form-control-solid"
              style={{ width: "100%" }}
              name="amountBkTnputRef"
              placeholder="0.00 $"
              onChange={(e) => {}}
            />

            {accessibleWalletData > 0 && (
              <button
                onClick={maxWalletValue}
                className="btn btn-sm fw-bold btn-light-success"
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10%",
                  transform: "translateY(-50%)",
                }}
              >
                MAX
              </button>
            )}
          </div>

          <div>
            <i
              className="fas fa-exclamation-circle mt-3 fs-7 ms-1"
              data-bs-toggle="tooltip"
              title="Specify your unique app name"
            ></i>{" "}
            <span className="text-muted w-25">
              Комиссия за операцию <span className="text-white">1 USDT</span>
              {accessibleWalletData > 0 && (
                <h6 className="mt-3 ms-2 text-white">
                  Доступно: {accessibleWalletData} {" USDT"}
                </h6>
              )}
            </span>
          </div>

          {/* end::Input */}
        </div>
        {/* end::Input group */}

        {error && ShowError(error)}

        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className="d-flex aligin-items-center justify-content-between pt-10 mb-2 mx-5">
          <button
            type="reset"
            onClick={() => cancel()}
            className="d-block  btn btn-light  px-6"
          >
            Отмена
          </button>

          <button
            type="button"
            disabled={isAllLoading}
            className="d-block ms-auto btn btn-primary  px-6"
            onClick={modalButtonClick}
          >
            {isAllLoading && (
              <span
                className="spinner-border spinner-border-sm me-4"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {intl.formatMessage({ id: "PROFILE.AMOUNT_ACTION" })}
          </button>
        </div>
      </div>
    </>
  );

  function modalButtonClick() {
    queryClient.clear();
    const cents = convertDollarsToCents(
      Number(amountBkTnputRef?.current?.value)
    );

    setDepositFreewin(createSendDataFreewin(cents), {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => {
            return ["wallet", "freewin-balance"].includes(
              query.queryKey as string
            );
          },
        });
        cancel();
      },
    });
  }
};

export { DepositeEditModalForm };
